<template>
  <div v-if="loadingNewFaqs" class="load-4">
        <div class="ring-2"></div>
      </div>
  <div v-else class="container-fluid mx-0 px-md-0" >
    <div class="bg-img-faq" >
      <p  class="title text-white div-plan" data-aos="fade-up">{{title}}</p>
     
    </div>
    
    <div class="row mx-md-5 px-md-5">
      <div class="col-md-3 my-md-0 mb-5" v-for="cate of data" :key="cate.id">
        <div
          :class="
            cate == cat
              ? 'block_cat_active bg-light  pointer border'
              : 'bg-light  pointer border block_cat'
          "
          @click="
            cat = cate;
            preguntasAMostrar = cate.preguntas;
          "
        >
          <p
            class="my-md-3 px-4 pt-md-4 pb-md-4 pt-3 pb-1 h5 block_cat_text h5"
          >
            {{ cate.categoria }}
          </p>
        </div>
      </div>
    </div>
    
    <div class="mt-md-5 text-left px-md-5 px-3">
      <div id="Limpieza" class="pb-5 px-md-5 mx-md-5">
        <!-- preguntas y resp-->
        <div v-for="(item, i) in preguntasAMostrar" :key="i">
          {{ item.preguntas }}
          <div class="pointer row mt-4 pt-3" @click="pregunta_respuesta(item)">
            <div class="col-md col-10">
              <p class="h5">{{ item.pregunta }}</p>
            </div>

            <div class="col-md col-2">
              <i
                :class="
                  item.visible == 1
                    ? 'fa fa-chevron-down text-right h2 text-primary rotate float'
                    : 'fa fa-chevron-down text-right h2 text-primary float'
                "
              ></i>
            </div>
          </div>

          <div v-if="item.visible == 1" class="row mt-md-0 mt-4">
            <div class="col-md-11">
              <p>{{ item.respuesta }}</p>
            </div>
          </div>
          <hr class="hr-style" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingNewFaqs :true,
      title: JSON.parse(localStorage.getItem('text')).navbar_titulo9, 
      cat: 1,
      preg_res: 0,
      nombre_categoria1: "General",
      nombre_categoria2: "Reservar con Linetor",
      nombre_categoria3: "Pagos y Comisiones",
      nombre_categoria4: "Información para Clientes",
      pregunta_categoria1: [
        {
          id: 1,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "General",
        },
        {
          id: 2,
          pregunta: "¿Cómo reservar un alojamiento bajo petición?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "General",
        },
        {
          id: 3,
          pregunta:
            "¿Puedo enviara a través del Portal del Partner ofertas a mis clientes? ¿Existe la opción de marca blanca?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "General",
        },
      ],
      pregunta_categoria2: [
        {
          id: 1,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "Reservar con Interhome Group",
        },
        {
          id: 2,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "Reservar con Interhome Group",
        },
        {
          id: 3,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "Reservar con Interhome Group",
        },
      ],
      pregunta_categoria3: [
        {
          id: 1,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "Pagos y Comisiones",
        },
        {
          id: 2,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "Pagos y Comisiones",
        },
        {
          id: 3,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "Pagos y Comisiones",
        }
      ],
      pregunta_categoria4: [
        {
          id: 1,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "Información para Clientes",
        },
        {
          id: 2,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "Información para Clientes",
        },
        {
          id: 3,
          pregunta: "¿Cómo me puedo registrar como partner?",
          respuesta:
            "Puede registrarse como colaborador rellenando el formulario de registro. Otra vía sería enviar un email a partnercare.es@interhome.group o llamar a nuestra central de reservas +34 93 409 05 22. Una vez recibamos sus detalles activaremos su cuenta para que pueda empezar a reservar.",
          visible: 0,
          nombre_categoria: "Información para Clientes",
        },
      ],
      data: [],
      preguntasAMostrar: [],
      lngKey:0
    };
  },
  async mounted() {
    ///////////////
    console.log('SE MONTO EL FAQ')
    console.log(this.loadingNewFaqs)
    this.title = JSON.parse(localStorage.getItem('text')).navbar_titulo9;
    this.$store.watch(
      (state) => [state.recargafaqsKey].join(),
      async () => {
        this.textos = JSON.parse(localStorage.getItem("textfaqs"));
        console.log("se activó watch en mounted faq, actualizando lista..");
        this.title = JSON.parse(localStorage.getItem('text')).navbar_titulo9;
        //await this.llenarCampos()
        this.loadingNewFaqs = true;
        this.llenarTextosFaqs();
        
      }
    );
    this.llenarTextosFaqs();

    ////////////////
  },
  methods: {
    async llenarTextosFaqs() {
      
      this.data = []
      this.preguntasAMostrar  = []
     
      let data = [];

      let tmp = JSON.parse(localStorage.getItem("textfaqs")) || [];
     
      
      
      for await (let it of tmp) {
        
        let datapregruntas = [];
        let identificador = 0;
        let jstemp = {};
        let textpregunta = "";
        for await (let itp of it.preguntas) {
          
          if(itp.texto?.trim().length > 0){ 
            if (identificador == itp.idrela) {
              // console.log("identificador es respuesta ", identificador);
              //es respuesta
              jstemp = {
                id: itp.idrela,
                pregunta: textpregunta,
                respuesta: itp.texto,
                visible: 0,
              };
              datapregruntas.push(jstemp);
              jstemp = {};
            } else {
              textpregunta = itp.texto;
             
              // console.log("identificador es pregunta ", jstemp);
            }
          }
          
          identificador = itp.idrela;
          
          // console.log("identificador,", identificador);
        }
        
        data.push({
          id: it.id,
          categoria: it.categoria,
          preguntas: datapregruntas,
        });
        datapregruntas = [];
      }

      this.data = data;
      this.preguntasAMostrar = this.data[0].preguntas;
      console.log("ppp ", this.preguntasAMostrar);
      this.lngKey++
      console.log('lngKey ', this.lngKey)
    //  this.$isLoading(false)
    this.loadingNewFaqs = false;
    },
    pregunta_respuesta(item) {
      item.visible = item.visible == 1 ? 0 : 1;
      if (this.pregunta_categoria1.id == this.pregunta_categoria1.id) {
          this.preg_res = true
          // this.preg_res = item
      }
      else{
          this.preg_res = false
      }
    },
  },
  watch: {
    cat(){
      this.title = JSON.parse(localStorage.getItem('text')).navbar_titulo9
    }
  }
};
</script>

<style>
.float {
  float: right;
}
.rotate {
  transform: rotate(3.142rad);
  animation: rotation 2s linear;
}
.hr-style {
  border: 1px solid rgb(224, 224, 224);
}
.block_cat {
  margin-top: -35px;
}
.block_cat:hover {
  border-bottom: 8px solid rgb(0, 174, 255) !important;

  box-shadow: 0px 8px 15px #24242454;
}
.block_cat_active {
  border-bottom: 8px solid rgb(0, 174, 255) !important;
  font-weight: bold !important;
  color: rgb(0, 174, 255) !important;
  box-shadow: 0px 8px 15px #24242454 !important;
  margin-top: -35px !important;
}

.block_cat_text:hover {
  font-weight: bold;
  animation: fadeIn;
  animation-duration: 1s;
}
.div-plan {
  padding-top: 190px;
  width: 66% !important;
  text-align: center !important;
  margin-left: 17%;
}
.bg-img-faq {
  /* The image used */
  /* background-image: url("/img/3.jpg"); */
  background-image: url("/img/faq2.png");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.load-4 .ring-2 {
  animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}
.ring-2 {
  width: 50px;
  height: 50px;
  margin: 100px auto;
  padding: 10px;
  border: 7px dashed #4b9cdb;
  border-radius: 100%;
}
@keyframes loadingD {
  0%{
    transform: rotate(0deg);
  }
   50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }

  
}
</style>
